import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2447676641/src/web-app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2447676641/src/web-app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2447676641/src/web-app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2447676641/src/web-app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2447676641/src/web-app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["CategoryCarousel"] */ "/codebuild/output/src2447676641/src/web-app/packages/webapp/app/(landing)/_component/CategoryCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MyCommunitiesCarousel"] */ "/codebuild/output/src2447676641/src/web-app/packages/webapp/app/(landing)/_component/MyCommunitiesCarousel.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2447676641/src/web-app/packages/webapp/app/(landing)/_component/RecommendedQuestsCarousel.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2447676641/src/web-app/packages/webapp/app/(landing)/_component/SprintsCarousel.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2447676641/src/web-app/packages/webapp/components/Carousel/Carousel.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2447676641/src/web-app/packages/webapp/components/CategoryCard/assets/blue.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2447676641/src/web-app/packages/webapp/components/CategoryCard/assets/cyan.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2447676641/src/web-app/packages/webapp/components/CategoryCard/assets/hotpink.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2447676641/src/web-app/packages/webapp/components/CategoryCard/assets/pink.webp");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2447676641/src/web-app/packages/webapp/components/CategoryCard/assets/yellow.webp");
;
import(/* webpackMode: "eager", webpackExports: ["Featured"] */ "/codebuild/output/src2447676641/src/web-app/packages/webapp/components/Featured/Featured.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2447676641/src/web-app/packages/webapp/components/Filters/Filters.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2447676641/src/web-app/packages/webapp/components/Hero/Hero.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2447676641/src/web-app/packages/webapp/components/Navbar/Navbar.tsx");
